<template>
  <div>
    <div class='md-layout'>
      <div class='md-layout-item'>
        <div class='btn-row'>
          <md-button class='md-success md-size-20 md-middle-size-100' @click='onShowImportDialog'>
            {{ $t('button.import') }}
          </md-button>
            <md-button class="md-success md-size-20 md-middle-size-100" @click="newItem">
              {{ $t('button.add') }}
            </md-button>

        </div>
        <md-card>
          <md-card-header class='md-card-header-icon md-card-header-green'>
            <div class='card-icon'>
              <md-icon>work</md-icon>
            </div>
            <h4 class='title'>{{ $t('label.disasters') }}</h4>
          </md-card-header>
          <md-card-content>
            <md-table
              :md-sort-fn='customSort'
              :md-sort-order.sync='currentSortOrder'
              :md-sort.sync='currentSort'
              :value='queriedData'
              class='paginated-table table-striped table-hover'
            >
              <md-table-toolbar>
                <md-field>
                  <label for='pages'>{{ $t('label.per_page') }}</label>
                  <md-select @input="onChangePerPage"
                             :value="pagination.perPage"
                             name="pages">
                    <md-option
                      v-for='item in pagination.perPageOptions'
                      :key='item'
                      :label='item'
                      :value='item'
                    >
                      {{ item === 'all' ? $t('reports.table_all') : item }}
                    </md-option>
                  </md-select>
                </md-field>

                <md-field>
                  <md-input
                    v-model='searchQuery'
                    :placeholder="$t('label.search_records')"
                    class='mb-3'
                    clearable
                    style='width: 200px'
                    type='search'
                  ></md-input>
                </md-field>
              </md-table-toolbar>

              <md-table-row slot='md-table-row' slot-scope='{item}'>
                <md-table-cell :md-label="$t('disaster.road_id')" md-sort-by='road_id'>
                  {{ item.road_id }}
                </md-table-cell>
                <md-table-cell :md-label="$t('disaster.key')" md-sort-by='key'>
                  {{ item.key }}
                </md-table-cell>
                <md-table-cell :md-label="$t('disaster.direction')" md-sort-by='direction'>
                  {{ item.direction }}
                </md-table-cell>
                <md-table-cell :md-label="$t('disaster.latitude')" md-sort-by='latitude'>
                  {{ item.latitude }}
                </md-table-cell>
                <md-table-cell :md-label="$t('disaster.longitude')" md-sort-by='longitude'>
                  {{ item.longitude }}
                </md-table-cell>
                <md-table-cell :md-label="$t('disaster.confirmed_risks')" md-sort-by='confirmed_risks'>
                  {{ $t(`disaster.${item.confirmed_risks}`) }}
                </md-table-cell>
                <md-table-cell :md-label="$t('disaster.rank')" md-sort-by='rank'>
                  {{ item.rank }}
                </md-table-cell>
                <md-table-cell :md-label="$t('disaster.total_points')" md-sort-by='total_points'>
                  {{ item.total_points }}
                </md-table-cell>
                <md-table-cell :md-label="$t('disaster.percent')" md-sort-by='percent'>
                  {{ item.percent }}
                </md-table-cell>

                <md-table-cell
                  :md-label="$t('tables.actions')"
                  class='btn-cont'
                >
                  <div class='cell-actions'>
                    <md-button
                        class='md-raised md-sm md-primary'
                        @click.stop.prevent='onEdit(item.id)'
                    >
                      {{ $t('button.edit') }}
                    </md-button>
                    <md-button
                        class='md-raised md-sm md-danger'
                        @click.stop.prevent='onDelete(item)'
                    >
                      {{ $t('button.delete') }}
                    </md-button>
                  </div>
                </md-table-cell>
              </md-table-row>
            </md-table>
            <div class='footer-table md-table'>
              <table>
                <tfoot>
                <tr>
                  <th
                    v-for='item in footerTable'
                    :key='item.name'
                    class='md-table-head'
                  >
                    <div
                      class='md-table-head-container md-ripple md-disabled'
                    >
                      <div class='md-table-head-label'>{{ item }}</div>
                    </div>
                  </th>
                </tr>
                </tfoot>
              </table>
            </div>
          </md-card-content>
          <md-card-actions md-alignment='space-between'>
            <div class>
              <p class='card-category'>
                {{
                  $t('label.showing_from_to_of_entries', {
                    from: to === 0 ? 0 : from + 1,
                    to: to,
                    total
                  })
                }}
              </p>
            </div>
            <pagination
              v-model='pagination.currentPage'
              :per-page='pagination.perPage'
              :total='total'
              class='pagination-no-border pagination-success'
            ></pagination>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <md-dialog :md-active.sync='showImportDialog'>
      <md-dialog-content>
        <div class='md-layout md-alignment-left'>

          <md-field>
            <label>{{ $t('file.upload_csv') }}</label>
            <md-file ref='importFile' v-model='csvFileName' :placeholder="$t('file.select_file')" accept='.csv' @md-change='onFileChanged' />
          </md-field>
          <md-field>
            <label for='delimiter'>{{ $t('import.delimiter') }}</label>
            <md-select id='delimiter' v-model='importDelimiter' name='delimiter'>
              <md-option value='semicolon'>;</md-option>
              <md-option value='comma'>,</md-option>
            </md-select>
          </md-field>
          <md-switch v-model='importHeader' value='true'>{{ $t('import.header') }}</md-switch>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button :disabled='!csvFile' class='md-primary md-sm' @click='onImport'>{{ $t('button.import') }}</md-button>
        <md-button class='md-default md-sm' @click='showImportDialog = false'>{{ $t('button.close') }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>

import {Pagination} from '@/components'
import Swal from 'sweetalert2'
// import {SlideYDownTransition} from 'vue2-transitions'
// import Fuse from 'fuse.js'

export default {
  name: 'maintenance_planning',
  components: {
    Pagination
  },
  data() {
    return {
      disasterList: [],
      showImportDialog: false,
      disasterDetails: {},
      importHeader: false,
      importDelimiter: 'semicolon',
      csvFileName: null,
      csvFile: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 'all'],
        total: 0
      },
      currentSort: 'key',
      currentSortOrder: 'asc',
      searchQuery: '',
      propsToSearch: ['key'],
      searchedData: [],
      fuseSearch: null,
      footerTable: []
    }
  },
  filters: {
    toDate: function(value) {
      return value.substring(0, 10)
    }
  },
  async beforeRouteUpdate(to, from, next) {
    await this.refreshListData()
    next()
  },
  async created() {
    await this.refreshListData()
  },
  methods: {
    onChangePerPage(val) {
      const limit = val === 'all' ? this.total : val
      this.pagination.perPage = limit
    },
    onFileChanged(val) {
      this.csvFile = val
    },
    onImport() {
      let formData = new FormData()

      formData.append('file', this.csvFile[0])
      formData.append('header', this.importHeader)
      formData.append('delimiter', this.importDelimiter)

      const alert = {
        type: 'success',
        text: this.$t(`import.data_were_added`),
        footer: ''
      }

      this.$store.dispatch('DISASTERS_IMPORT', formData).then(
        (location) => {
          alert.footer = `<a href='${location}' target='_blank'> Check Log file </a>`
          Swal.fire(alert).then(() => {
            this.showImportDialog = false
          })

        },
        () => {
          alert.type = 'error'
          alert.text = this.$t(`import.data_were_not_added`)
          Swal.fire(alert)
        }
      )
    },
    onShowImportDialog() {
      this.importDelimiter = 'semicolon'
      this.importHeader = false
      this.csvFile = null
      this.showImportDialog = true
    },
    calcPercent(percent) {
      return Math.round(percent)
    },
    async refreshListData() {
      this.disasterList = await this.$store.dispatch('LOAD_DISASTER_LIST')
    },
    newItem() {
      this.$router.push({name: "disaster-add"})
    },


    async onEdit(id) {
      this.$router.push({name: 'disaster-upd', params: {id: id}})
    },

    onDelete(item) {
      const alert = {
        icon: 'success',
        text: this.$t(`label.disaster_was_deleted`),
        footer: ''
      }
      Swal.fire({
        title: `${this.$t("label.are_you_sure")}`,
        text: `${this.$t("label.do_you_want_to_delete_disaster")} ${item.id}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-danger btn-fill",
        cancelButtonClass: "md-button md-default btn-fill",
        confirmButtonText: `${this.$t("label.yes_delete_it")}`,
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          try {
            await this.$store.dispatch("DELETE_DISASTER", item.id)
            const ind = this.disasterList.findIndex(d => d.id === item.id)
            this.disasterList.splice(ind, 1)
            Swal.fire({
              title: this.$t("label.deleted"),
              text: this.$t("label.disaster_was_deleted"),
              type: "success",
              confirmButtonClass: "md-button md-success btn-fill",
              buttonsStyling: false
            });
          } catch (err) {
            alert.type = 'error'
            alert.text = this.$t(`label.disaster_was_not_deleted`)
            alert.footer = this.$t(err.message ? err.message : err)
            Swal.fire(alert)
            //throw err
          }
        }
      });
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'asc') {
          return a[sortBy]
            .toString()
            .localeCompare(b[sortBy].toString(), undefined, {numeric: true})
        }
        return b[sortBy]
          .toString()
          .localeCompare(a[sortBy].toString(), undefined, {numeric: true})
      })
    }
  },
  computed: {
    queriedData() {
      const result = this.searchQuery
        ? this.searchedData
          ? this.searchedData
          : []
        : this.tableData
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchQuery ? this.searchedData.length : this.tableData.length
    },
    tableData() {
      return this.disasterList
    }
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      const regex = new RegExp(`${value}`, 'i')
      if (value !== '') {
        result = this.tableData.filter((row) => {
          return Object.values(row).reduce((incl, elem) => {
            return incl || regex.test(elem)
          }, false)
        })
      }
      this.searchedData = result
    }
  }
}

</script>

<style lang='scss' scoped>

.row-detail {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

}

.modified-percent {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 5px;

  .md-progress-bar {
    width: 100%;
    margin: 4px 5px;
  }

  span {
    width: 4rem;
  }
}

.cost-overhead {
  color: red;
}

.cost-good {
  color: green;
}

.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  button {
    margin-left: 10px
  }
}
</style>